<template lang="pug">
div.expande-horizontal.wrap.windows-style-content(style="overflow: auto; max-height: 90vh;")
  //- menuConfig(:view="showView" v-if="showView === '' || $vuetify.breakpoint.lgAndUp")
  v-flex(xs12)
    templateConfig(:store="store")
  v-flex(xs12)
    div.expande-horizontal.centraliza
      storeConfig(:store="store")
  v-flex(xs12)
    div.expande-horizontal.centraliza
      localConfig(:local="local")
  //- tableConfig(:local="local")
  //- receiptConfig(:view="showView", :receipt="receipt")
  v-flex(xs12)
    div.expande-horizontal.centraliza
      taxConfig(:payment_sys_config="payment_sys_config" :payment_menurocket_config="payment_menurocket_config")
  //- deliveryConfig(:view="showView",v-if="showView === 'area-de-entrega'")
</template>

<script>
import menuConfig from "../Desktop/Menu/Index.vue";
import localConfig from "../Desktop/Local/Index.vue";
import tableConfig from "../Desktop/Table/Index.vue";
import receiptConfig from "../Desktop/Receipt/Index.vue";
import taxConfig from "../Desktop/Tax/Index.vue";
import templateConfig from "../Desktop/Template/Index.vue";
import deliveryConfig from "../Desktop/Delivery/Index.vue";
import storeConfig from "../Desktop/Store/Index.vue";
import { EventBus } from "@/main.js";
export default {
  props: [
    "local",
    "payment_sys_config",
    "payment_menurocket_config",
    "receipt",
    "store"
  ],
  data() {
    return {
      showView: "store-profile"
    };
  },
  components: {
    menuConfig,
    localConfig,
    tableConfig,
    receiptConfig,
    taxConfig,
    deliveryConfig,
    storeConfig,
    templateConfig
  },
  methods: {},
  created() {
    const self = this;
    EventBus.$on("set-config-view", view => {
      self.showView = view;
      self.$forceUpdate();
    });
  }
};
</script>
