<template lang="pug">
v-flex(xs12 md6 :class="{ 'pl-6': $vuetify.breakpoint.lgAndUp }")
    v-card.expande-horizontal.wrap.elevation-0( color="transparent" :class="{ 'pa-6 elevation-0': $vuetify.breakpoint.lgAndUp }")
        div.expande-horizontal.centraliza.column
            div.expande-horizontal.centraliza
                v-flex(xs12)
                    v-list(two-line,style="width: 100%;" color="transparent")
                        v-list-item
                            v-avatar.mr-1(:color="$theme.primary")
                                v-icon(size="31",color="white") mdi-cog-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte.font-weight-bold(:style="`color: ${$theme.primary}`") Consumo no local e retiradas 
                                v-list-item-subtitle
                                    span.fonte.grey--text Configure abaixo 

                        v-list-item.pl-10
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-storefront-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Consumo no local 
                            v-list-item-action.pt-3
                                v-switch(@change="saveLocal",v-model="local.active",:color="$theme.secondary")
                        //- v-list-item
                        //-     v-avatar
                        //-             v-icon(size="31",color="#363636") mdi-qrcode-scan
                        //-     v-list-item-content 
                        //-         v-list-item-title
                        //-             span.fonte Ao ler QR-Code solicitar nome e telefone do cliente
                        //-     v-list-item-action.pt-3
                        //-         v-switch(@change="saveLocal",v-model="local.ask_name_and_phone" :color="$theme.secondary")
                        //- v-list-item
                        //-     v-avatar
                        //-             v-icon(size="31",color="#363636") mdi-account-tie-outline
                        //-     v-list-item-content 
                        //-         v-list-item-title
                        //-             span.fonte O Cliente poderá chamar o Garçom pelo App
                        //-     v-list-item-action.pt-3
                        //-         v-switch(@change="saveLocal",v-model="local.allow_call_waiter",:color="$theme.secondary")
                        //- v-list-item
                        //-     v-avatar
                        //-             v-icon(size="31",color="#363636") mdi-account-cash-outline
                        //-     v-list-item-content 
                        //-         v-list-item-title
                        //-             span.fonte Solicitar comissão para o garçom
                        //-     v-list-item-action.pt-3
                        //-         v-switch(@change="saveLocal",v-model="local.allow_waiter_commission",:color="$theme.secondary")
                        v-list-item.pl-10
                            v-avatar
                                    v-icon(size="31",color="#363636") mdi-shopping-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Retirada no local
                            v-list-item-action.pt-3
                                v-switch(@change="saveLocal",v-model="local.local_pickup",:color="$theme.secondary")
                    TableConfig(v-if="local.active", :local="local")

</template>

<script>
import TableConfig from '../Table/Index.vue'
import { EventBus } from "@/main";
export default {
  props: ["local"],
  components: { TableConfig },
  methods: {
    changeConfigView(view) {
      EventBus.$emit("set-config-view", view);
    },
    saveLocal() {
      console.log("local", this.local);
      EventBus.$emit("saveLocal", this.local);
    }
  }
};
</script>

<style>
.config-local-avatar {
  width: 150px;
  height: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
}
</style>
