<template>
  <div class="expande-horizontal centraliza column px-6">
    <h3 class="fonte-bold">Escolha a cara do seu negócio</h3>

    <span class="fonte">* Cardapios</span>
    <div class="expande-horizontal centraliza wrap">
      <v-flex
        class="pa-3"
        v-for="cardapio in cardapios"
        :key="cardapio.id"
        xs12
        md3
      >
        <div
          @click="setDefaultTemplate(cardapio)"
          class="card-container"
          :class="{ 'default-template': cardapio.id == selecionado }"
          hover
          color="#F5F5F5"
        >
          <div
            class="card-content-layer"
            :style="`backgroundImage: url(${cardapio.imagem})`"
          >
            <div class="card-content centraliza column pa-3 px-6">
              <h3 class="white--text">{{ cardapio.name }}</h3>
              <v-progress-linear
                indeterminate
                color="white"
                style="width: 100px; height: 10px; border-radius: 10px;"
                v-if="loading"
              ></v-progress-linear>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <!-- <span class="fonte mt-6">* Catálogos</span> -->
    <div v-if="false" class="expande-horizontal centraliza wrap">
      <v-flex
        class="pa-3"
        v-for="catalogo in catalogos"
        :key="catalogo.id"
        xs12
        md3
      >
        <div
          @click="setDefaultTemplate(catalogo)"
          class="card-container"
          :class="{ 'default-template': catalogo.id == selecionado }"
          hover
          color="#F5F5F5"
        >
          <div
            class="card-content-layer"
            :style="`backgroundImage: url(${catalogo.imagem})`"
          >
            <div class="card-content centraliza column pa-3 px-6">
              <h3 class="white--text">{{ catalogo.name }}</h3>
              <v-progress-linear
                indeterminate
                color="white"
                style="width: 100px; height: 10px; border-radius: 10px;"
                v-if="loading"
              ></v-progress-linear>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </div>
</template>

<script>
export default {
  props: ["store"],
  data: () => ({
    loading: false,
    cardapios: [
      {
        id: "classico",
        imagem:
          "/templates/classic.png",
        name: "Clássico"
      },
      {
        id: "caroline",
        imagem: "/templates/caroline.png",
        name: "Caroline"
      }
    ],
    catalogos: [
      {
        id: "ecommerce-black",
        imagem:
          "https://static.vecteezy.com/ti/fotos-gratis/t2/17323707-close-up-de-um-reptil-em-um-galho-de-arvore-contra-um-impressionante-papel-de-parede-de-fundo-natural-hd-foto.jpg",
        name: "E-commerce Black"
      },
      {
        id: "ecommerce-red",
        imagem:
          "https://static.vecteezy.com/ti/fotos-gratis/t2/17323707-close-up-de-um-reptil-em-um-galho-de-arvore-contra-um-impressionante-papel-de-parede-de-fundo-natural-hd-foto.jpg",
        name: "E-commerce Red"
      }
    ],
    selecionado: () =>
      this.store.default_template || {
        id: "classico"
      }
  }),
  methods: {
    setDefaultTemplate(defaultTemplate) {
      this.loading = true;
      this.$run("marketplaces/set-default-template", {
        default_template: defaultTemplate
      })
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          alert("Erro ao salvar");
        });
      this.selecionado = defaultTemplate.id;
    },
    async getMyProfile() {
      this.loading = true;
      this.$run("marketplaces/show-my-profile")
        .then(res => {
          this.selecionado = res.data.profile.default_template.id;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  mounted() {
    this.getMyProfile();
  }
};
</script>

<style lang="scss">
.card-container {
  display: flex;
  position: relative;
  overflow: hidden;
  background: #f0f0f0;
  height: 400px;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  border: 5px solid #333 !important;
  box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.2);
}
.card-content-layer {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
  background: rgba(0, 0, 0, 0.5);
  background-size: cover;
  // background-image: url("");
}
.card-content {
  display: flex;
  width: 100%;
  height: 100%;
  transition: background 0.3s ease;
  transition: opacity 0.3s ease;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
}
.default-template {
  border: 6px solid #9464e6 !important;
}
.card-content:hover {
  background: rgba(0, 0, 0, 0.3);
}
.card-content-layer:hover {
  transform: scale(1.1);
  .card-content {
    opacity: 1;
  }
}
</style>
