<template>
  <v-flex xs12 md6>
    <v-flex xs12>
      <v-flex xs12>
        <div class="expande-horizontal column centraliza">
          <div class="store-layout">
            <div
              style="position: relative; top: 10; right: 10; bottom: -40px; left: 6px;"
            >
              <v-btn @click="openUploadCoverContainer" icon color="#333">
                <v-icon color="#fff">mdi-image-edit-outline</v-icon>
              </v-btn>
            </div>
            <img class="store-cover" :src="coverImage" />
            <div class="profile-container">
              <img
                style="z-index: 300;"
                @click.prevent="openUploadProfileContainer"
                class="store-profile"
                :src="profileImage"
              />
              <h3 class="text-center fonte white--text">{{ profile.nome }}</h3>
            </div>
          </div>
        </div>
        <div class="mt-3 fonte expande-horizontal">
          <div class="expande-horizontal">
            <input
              id="cover"
              label="Faça o upload da foto de capa"
              type="file"
              ref="cover_upload"
              @change="preUploadCoverImage"
            />
            <!-- <p v-if="coverProgress">{{ coverProgress }}%</p> -->
          </div>
          <div>
            <input
              id="image_profile"
              type="file"
              ref="image_profile_upload"
              @change="preUploadProfileImage"
            />
            <!-- <p v-if="profileProgress">{{ profileProgress }}%</p> -->
          </div>
        </div>
      </v-flex>
      <div class="expande-horizontal wrap">
        <v-flex xs12>
          <!-- {{ profile }} -->
          <v-form ref="formMinhaLoja">
            <div class="expande-horizontal fonte wrap">
              <v-flex xs12 md6 class="px-1">
                <v-text-field
                  filled
                  rounded
                  dense
                  background-color="#e5e5e5"
                  class="fonte-bold"
                  append-icon="mdi-content-save"
                  @click:append="saveProfile"
                  color="#333"
                  label="Nome da Loja"
                  placeholder="ex: SCNRevolution"
                  v-model="profile.nome"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 class="px-1">
                <v-text-field
                  background-color="#e5e5e5"
                  class="fonte-bold"
                  append-icon="mdi-content-save"
                  filled
                  rounded
                  dense
                  label="Endereço"
                  color="#333"
                  placeholder="ex: Escreva o endereço aqui"
                  @click:append="saveAddress($event)"
                  v-model="profile.address.formatted_address"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 class="pa-1">
                <div class="expande-horizontal wrap">
                  Link da loja: https://catalogo.scncommerce.com.br/#/{{
                    profile.link_menurocket
                  }}
                </div>
              </v-flex> -->
              <v-flex xs12 class="px-1">
                <v-text-field
                  background-color="#e5e5e5"
                  filled
                  rounded
                  dense
                  readonly
                  label="CNPJ"
                  class="fonte-bold"
                  color="#333"
                  placeholder="ex: Insira seu CNPJ aqui"
                  v-model="profile.cnpj"
                ></v-text-field>
              </v-flex>
            </div>
          </v-form>
        </v-flex>
      </div>
    </v-flex>
    <v-dialog
      fullscreen
      transition="slide-x-transition"
      v-model="cropCoverImage"
    >
      <v-card
        dark
        class="expande-horizontal wrap centraliza"
        style="min-height: 100vh;"
      >
        <v-flex xs12>
          <v-list class="pa-0 ma-0 fonte">
            <v-list-item class="pr-6" @click="cropCoverImage = false">
              <v-avatar class="mr-3" :color="$theme.secondary" icon>
                <v-icon color="#fff">mdi-arrow-left</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  Ajuste a foto de capa
                </v-list-item-title>
                <v-list-item-subtitle
                  >será considerado apenas o que estiver
                  selecionado</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="uploadCoverImage" :color="$theme.secondary" dark
                  >Salvar</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-flex>
        <div class="pl-6 pr-6 expande-horizontal wrap">
          <v-flex xs12>
            <div style="width: 100%; height: 80vh;">
              <vue-cropper
                style="border-radius: 6px;"
                ref="cropper10"
                :img="cropCoverImageData"
                :output-size="option.size"
                :output-type="option.outputType"
                :info="true"
                :full="option.full"
                :fixed="fixed"
                :fixed-number="fixedNumber"
                :can-move="option.canMove"
                :can-move-box="option.canMoveBox"
                :fixed-box="option.fixedBox"
                :original="option.original"
                :auto-crop="option.autoCrop"
                :auto-crop-width="option.autoCropWidth"
                :auto-crop-height="option.autoCropHeight"
                :center-box="option.centerBox"
                @real-time="realTime"
                :high="option.high"
                @img-load="imgLoad"
                mode="cover"
                :max-img-size="option.max"
                @crop-moving="cropMoving"
                @crop-complete="uploadProfileImage"
              ></vue-cropper>
            </div>
          </v-flex>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      fullscreen
      transition="slide-x-transition"
      v-model="cropProfileImage"
    >
      <v-card
        dark
        class="expande-horizontal wrap centraliza"
        style="min-height: 100vh;"
      >
        <v-flex xs12>
          <v-list class="pa-0 ma-0 fonte">
            <v-list-item class="pr-6" @click="cropProfileImage = false">
              <v-avatar class="mr-3" :color="$theme.secondary" icon>
                <v-icon color="#fff">mdi-arrow-left</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  Ajuste a foto de perfil
                </v-list-item-title>
                <v-list-item-subtitle
                  >será considerado apenas o que estiver
                  selecionado</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  @click="uploadProfileImage"
                  :color="$theme.secondary"
                  dark
                  >Salvar</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-flex>
        <div class="pl-6 pr-6 expande-horizontal wrap">
          <v-flex xs12>
            <div style="width: 100%; height: 80vh;">
              <vue-cropper
                style="border-radius: 6px;"
                ref="cropper20"
                :img="cropProfileImageData"
                :output-size="option2.size"
                :output-type="option2.outputType"
                :info="true"
                :full="option2.full"
                :fixed="fixed2"
                :fixed-number="fixedNumber2"
                :can-move="option2.canMove"
                :can-move-box="option2.canMoveBox"
                :fixed-box="option2.fixedBox"
                :original="option2.original"
                :auto-crop="option2.autoCrop"
                :auto-crop-width="option2.autoCropWidth"
                :auto-crop-height="option2.autoCropHeight"
                :center-box="option2.centerBox"
                @real-time="realTime"
                :high="option2.high"
                @img-load="imgLoad"
                mode="cover"
                :max-img-size="option2.max"
                @crop-moving="cropMoving"
                @crop-complete="uploadProfileImage"
              ></vue-cropper>
            </div>
          </v-flex>
        </div>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { VueCropper } from "vue-cropper";
import { EventBus } from "@/main";
import { getStorage, ref, getDownloadURL, uploadBytes, uploadBytesResumable, deleteObject  } from "firebase/storage";


export default {
  props: ["local", "store"],
  data() {
    return {
      moment: moment,
      coverImage: "https://via.placeholder.com/1600x400",
      profileImage: "https://via.placeholder.com/512x512",
      coverProgress: 0,
      profileProgress: 0,
      cropCoverImage: false,
      cropCoverImageData: "",
      cropProfileImage: false,
      cropProfileImageData: "",
      option: {
        size: 1,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 512,
        autoCropHeight: 512,
        centerBox: false,
        high: false,
        max: 99999
      },
      option2: {
        size: 1,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 120,
        autoCropHeight: 120,
        centerBox: false,
        high: false,
        max: 9999
      },
      fixed: true,
      fixedNumber: [16, 4],
      fixed2: true,
      fixedNumber2: [1, 1],
      profile: {
        address: {}
      },
      charges: {},
      subscription: {}
    };
  },
  components: {
    VueCropper
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    daysLeft() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      const days = moment(expires).diff(moment(), "days");
      return days;
    },
    dateExpire() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      return moment(expires).format("DD/MM/YYYY [às] HH:mm");
    }
  },
  filters: {
    filterStatusSubscription(val) {
      switch (val) {
        case "active":
          return "ativa";
        default:
          return "";
      }
    }
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    changeConfigView(view) {
      EventBus.$emit("set-config-view", view);
    },
    saveLocal() {
      EventBus.$emit("saveLocal", this.local);
    },
    openUploadProfileContainer() {
      this.$refs.image_profile_upload.click();
    },
    openUploadCoverContainer() {
      this.$refs.cover_upload.click();
    },
    cropMoving() {},
    cropImg() {},
    imgLoad() {},
    realTime() {},
    preUploadCoverImage() {
      const input = this.$refs.cover_upload;
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.cropCoverImageData = e.target.result;
          this.cropCoverImage = true;
        };
      };
      reader.readAsDataURL(file);
    },
    preUploadProfileImage() {
      const input = this.$refs.image_profile_upload;
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.cropProfileImageData = e.target.result;
          this.cropProfileImage = true;
        };
      };
      reader.readAsDataURL(file);
    },
    async uploadCoverImage() {
      const self = this;

      this.$refs.cropper10.getCropBlob(async data => {
        var metadata = {
          contentType: data.type
        };
        const storage = getStorage();
        const storageRef = ref(storage);
        const imageRef = ref(
          storageRef,
          `cover_images/${this.getLoggedUser.activeTenant._id}.png`
        );
        
        const task = uploadBytesResumable(imageRef, data, metadata);
        task.on(
          "state_changed",
          snapshot => {
            self.coverProgress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.error(error);
          },
          async () => {
            self.coverImage = await getDownloadURL(task.snapshot.ref);
            self.saveProfileImage("coverImage");
          }
        );
      });
    },
    async uploadProfileImage() {
      const self = this;
      this.$refs.cropper20.getCropBlob(async data => {
        var metadata = {
          contentType: data.type
        };
        const storage = getStorage();
        const storageRef = ref(storage);
        const imageRef = ref(
          storageRef,
          `store_images/${this.getLoggedUser.activeTenant._id}.png`
        );
        const task = uploadBytesResumable(imageRef, data, metadata);
        task.on(
          "state_changed",
          snapshot => {
            self.profileProgress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.error(error);
          },
          async () => {
            self.profileImage = await getDownloadURL(task.snapshot.ref);
            self.saveProfileImage("profileImage");
          }
        );
      });
    },
    async getMyProfile() {
      this.loading = true;
      this.$run("marketplaces/show-my-profile")
        .then(res => {
          if (res.data.profile.coverImage) {
            this.coverImage = res.data.profile.coverImage;
          }
          if (res.data.profile.profileImage) {
            this.profileImage = res.data.profile.profileImage;
          }
          this.subscription = res.data.subscription;
          this.profile = {
            ...res.data.profile,
            address: res.data.profile.address
              ? res.data.profile.address
              : { formatted_address: "" }
          };
          this.charges = res.data.charges;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    saveAddress() {
      this.loading = true;
      let data = {
        address: {
          formatted_address: this.profile.address.formatted_address
        }
      };
      this.$run("marketplaces/set-profile-image", data)
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    async saveProfileImage(type) {
      this.loading = true;
      let data = {};
      data[type] = this[type];
      this.$run("marketplaces/set-profile-image", data)
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    saveProfile() {
      this.loading = true;
      this.$run("marketplaces/set-store-info", {
        nome: this.profile.nome,
        descricao: this.profile.descricao
      })
        .then(() => {
          this.loading = false;
          this.createGlobalMessage({
            type: "success",
            message: "Nome salvo      🏡😁",
            timeout: 3000
          });
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  mounted() {
    this.getMyProfile();
  }
};
</script>

<style>
.store-layout {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 120px;
  cursor: pointer;
}

.store-cover {
  width: 100%;
  min-height: 220px;
  border-radius: 6px;
}

.profile-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}

.store-profile {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.store-profile:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.item-list-charge {
  background: #f2f2f2;
  border-radius: 6px;
}
</style>
