<template lang="pug">
v-flex(xs12 md9 :class="{ 'pl-6': $vuetify.breakpoint.lgAndUp }")
    v-card.pb-6.expande-horizontal.wrap(:class="{ 'pa-6': $vuetify.breakpoint.lgAndUp }")
        div.expande-horizontal.centraliza.column
            div.expande-horizontal(v-if="$vuetify.breakpoint.smAndDown")
                v-btn(dark icon @click="changeConfigView('')")
                    v-icon(color="black") mdi-arrow-left
            div.config-receipt-avatar 
                img(width="80",height="100",src="img/config/receipt.svg")
            h4.fonte.pt-6.pb-12 Recibo
            span.fonte Personalize seu recibo de impressão
            div.expande-horizontal.centraliza.mt-12
                v-flex(xs12 md8)
                    //- h3.fonte Recibo do cliente
                    v-list(two-line,style="width: 100%;")
                        v-list-item
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Mostrar meu endereço
                            v-list-item-action.pt-3
                                v-switch(@change="saveReceipt",v-model="receipt.show_my_address",:color="$theme.secondary")
                        v-list-item
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Mostrar o meu logotipo
                            v-list-item-action.pt-3
                                v-switch(@change="saveReceipt",v-model="receipt.show_my_logo",:color="$theme.secondary")
                        v-list-item
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Mostrar meu QRCode
                            v-list-item-action.pt-3
                                v-switch(@change="saveReceipt",v-model="receipt.show_my_qrcode",:color="$theme.secondary")
                        v-divider.mt-3.mb-3
                        v-list-item
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Adicionar dados do cliente
                                v-list-item-subtitle
                                    span.fonte Nome, endereço e telefone
                            v-list-item-action.pt-3
                                v-switch(@change="saveReceipt",v-model="receipt.show_client_details",:color="$theme.secondary")
                        v-divider.mt-3.mb-3
                    v-flex(xs8).ma-3
                        v-text-field(
                            outlined 
                            dense
                            v-model="receipt.show_header_text.text"
                            label="Texto de Cabeçalho"
                        )
                    v-flex(xs8).ma-3
                        v-text-field(
                            outlined 
                            dense
                            v-model="receipt.show_footer_text.text"
                            label="Texto de Rodapé"
                        )
                    v-flex(xs12).ml-3
                        v-btn.fonte.white--text(@click="saveReceipt",:color="$theme.third") Salvar textos
                        
</template>

<script>
import { EventBus } from "@/main";
export default {
  props: {
    receipt: {
      type: Object,
      default: () => {
        return {
          show_my_address: { active: true },
          show_my_logo: { active: true },
          show_my_qrcode: { active: true },
          show_client_details: { active: true },
          show_header_text: { active: true, text: "" },
          show_footer_text: { active: true, text: "" }
        };
      }
    }
  },
  methods: {
    changeConfigView(view) {
      EventBus.$emit("set-config-view", view);
    },
    saveReceipt() {
      EventBus.$emit("saveReceiptConfig", this.receipt);
    }
  }
};
</script>

<style>
.config-receipt-avatar {
  width: 150px;
  height: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
}
</style>
