<template lang="pug">
v-flex(xs12 md6 :class="{ 'pl-6': $vuetify.breakpoint.lgAndUp }")
    v-card.elevation-0().expande-horizontal.wrap.pb-3(color="transparent" :class="{ 'pa-6': $vuetify.breakpoint.lgAndUp }")
        div.expande-horizontal.column
            v-flex(xs12).px-2
                v-list(color="transparent")
                    v-list-item
                        v-avatar.mr-1(:color="$theme.primary")
                            v-icon(size="31",color="white") mdi-cash
                        v-list-item-content 
                            v-list-item-title
                                span.fonte.font-weight-bold(:style="`color: ${$theme.primary}`") Formas de pagamento
                            v-list-item-subtitle
                                span.fonte.grey--text Ative as que você aceita
            v-tabs.px-3(style="border-radius: 6px;",background-color="#f2f2f2",:color="$theme.secondary", v-model="tax_tab", centered)
                v-tab.fonte Venda interna
                v-tab.fonte Catálogo
            v-flex(xs12)
                v-divider
            div.expande-horizontal.centraliza(v-if="tax_tab === 0")
                v-flex(xs12)
                    v-list(two-line,style="width: 100%;" color="transparent")
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-cash
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Dinheiro
                                    //- v-flex(xs4)
                                    //-     v-text-field(
                                    //-         outlined 
                                    //-         dense
                                    //-         v-if="payment_sys_config['money'].active"
                                    //-         hide-details
                                    //-         type="Number"
                                    //-         suffix="%"
                                    //-         placeholder="ex: 2.89"
                                    //-         v-model="payment_sys_config['money'].tax_porcentage"
                                    //-     )
                            v-list-item-action.pt-3
                                v-switch(@change="savePaymentSysConfig",v-model="payment_sys_config['money'].active",:color="$theme.secondary")
                        v-divider 
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-credit-card-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Cartão de Crédito
                                    //- v-flex(xs4)
                                    //-     v-text-field(
                                    //-         outlined 
                                    //-         dense
                                    //-         v-if="payment_sys_config['card'].active"
                                    //-         suffix="%"
                                    //-         hide-details
                                    //-         type="Number"
                                    //-         placeholder="ex: 2.89"
                                    //-         v-model="payment_sys_config['card'].tax_porcentage"
                                    //-     )
                            v-list-item-action.pt-3
                                v-switch(@change="savePaymentSysConfig",v-model="payment_sys_config['card'].active",:color="$theme.secondary")
                        v-divider 
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-credit-card-chip-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Cartão de Débito
                                    //- v-flex(xs4)
                                    //-     v-text-field(
                                    //-         outlined 
                                    //-         dense
                                    //-         v-if="payment_sys_config['debit'].active"
                                    //-         suffix="%"
                                    //-         hide-details
                                    //-         type="Number"
                                    //-         placeholder="ex: 2.89"
                                    //-         v-model="payment_sys_config['debit'].tax_porcentage"
                                    //-     )
                            v-list-item-action.pt-3
                                v-switch(@change="savePaymentSysConfig",v-model="payment_sys_config['debit'].active",:color="$theme.secondary")
                        v-divider 
                        v-list-item
                            v-avatar
                                img(style="width: 27px;",src="img/config/pix.svg",color="#363636")
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Pix
                                    //- v-flex(xs4)
                                    //-     v-text-field(
                                    //-         outlined 
                                    //-         dense
                                    //-         v-if="payment_sys_config['pix'].active"
                                    //-         suffix="%"
                                    //-         hide-details
                                    //-         type="Number"
                                    //-         placeholder="ex: 2.89"
                                    //-         v-model="payment_sys_config['pix'].tax_porcentage"
                                    //-     )
                            v-list-item-action.pt-3
                                v-switch(@change="savePaymentSysConfig",v-model="payment_sys_config['pix'].active",:color="$theme.secondary")
                        v-divider 
                    //- v-flex.px-3(xs12)
                    //-     v-btn(block @click="savePaymentSysConfig", :color="$theme.third", dark) Salvar
            div.expande-horizontal.centraliza(v-if="tax_tab === 1")
                v-flex(xs12)
                    v-list(two-line,style="width: 100%;")
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-cash
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Dinheiro
                                    //- v-flex(xs4)
                                    //-     v-text-field(
                                    //-         outlined 
                                    //-         dense
                                    //-         v-if="payment_menurocket_config['money'].active"
                                    //-         hide-details
                                    //-         type="Number"
                                    //-         suffix="%"
                                    //-         placeholder="ex: 2.89"
                                    //-         v-model="payment_menurocket_config['money'].tax_porcentage"
                                    //-     )
                            v-list-item-action.pt-3
                                v-switch(@change="savePaymentMenurocketConfig",v-model="payment_menurocket_config['money'].active",:color="$theme.secondary")
                        v-divider 
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-credit-card-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Cartão de Crédito
                                    //- v-flex(xs4)
                                    //-     v-text-field(
                                    //-         outlined 
                                    //-         dense
                                    //-         v-if="payment_menurocket_config['card'].active"
                                    //-         suffix="%"
                                    //-         hide-details
                                    //-         type="Number"
                                    //-         placeholder="ex: 2.89"
                                    //-         v-model="payment_menurocket_config['card'].tax_porcentage"
                                    //-     )
                            v-list-item-action.pt-3
                                v-switch(@change="savePaymentMenurocketConfig",v-model="payment_menurocket_config['card'].active",:color="$theme.secondary")
                        v-divider 
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-credit-card-chip-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Cartão de Débito
                                    //- v-flex(xs4)
                                    //-     v-text-field(
                                    //-         outlined 
                                    //-         dense
                                    //-         v-if="payment_menurocket_config['debit'].active"
                                    //-         suffix="%"
                                    //-         hide-details
                                    //-         type="Number"
                                    //-         placeholder="ex: 2.89"
                                    //-         v-model="payment_menurocket_config['debit'].tax_porcentage"
                                    //-     )
                            v-list-item-action.pt-3
                                v-switch(@change="savePaymentMenurocketConfig",v-model="payment_menurocket_config['debit'].active",:color="$theme.secondary")
                        v-divider 
                        v-list-item
                            v-avatar
                                img(style="width: 27px;",src="img/config/pix.svg",color="#363636")
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Pix
                                    //- v-flex(xs4)
                                    //-     v-text-field(
                                    //-         outlined 
                                    //-         dense
                                    //-         v-if="payment_menurocket_config['pix'].active"
                                    //-         suffix="%"
                                    //-         hide-details
                                    //-         type="Number"
                                    //-         placeholder="ex: 2.89"
                                    //-         v-model="payment_menurocket_config['pix'].tax_porcentage"
                                    //-     )
                            v-list-item-action.pt-3
                                v-switch(@change="savePaymentMenurocketConfig",v-model="payment_menurocket_config['pix'].active",:color="$theme.secondary")
                        v-divider 
                    //- v-flex.px-3(xs12)
                    //-     v-btn(block @click="savePaymentMenurocketConfig", :color="$theme.third", dark) Salvar

</template>

<script>
import { EventBus } from "@/main";
export default {
  props: ["payment_sys_config", "payment_menurocket_config"],
  data() {
    return {
      tax_tab: 0
    };
  },
  methods: {
    changeConfigView(view) {
      EventBus.$emit("set-config-view", view);
    },
    savePaymentSysConfig() {
      EventBus.$emit("savePaymentSysConfig", this.payment_sys_config);
    },
    savePaymentMenurocketConfig() {
      EventBus.$emit(
        "savePaymentMenurocketConfig",
        this.payment_menurocket_config
      );
    },
    save() {
      console.log("save");
    }
  }
};
</script>

<style>
.config-local-avatar {
  width: 150px;
  height: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
}
</style>
