<template lang="pug">
v-flex(xs9).pl-6
    v-card.expande-horizontal.wrap.pa-6 
        div.expande-horizontal.centraliza.column
            h4.fonte.pt-6.pb-12 Consumo no local e retiradas
            div.expande-horizontal.centraliza
                v-flex(xs8)
                    v-list(two-line,style="width: 100%;")
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-storefront-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Consumo no local 
                            v-list-item-action.pt-3
                                v-switch(@change="saveLocal",v-model="local.active",:color="$theme.secondary")
                        v-list-item(@click="changeConfigView('table')")
                            v-avatar
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte(:style="`color: ${$theme.secondary}`") Configurar mesas
                        //- v-list-item
                        //-     v-avatar
                        //-             v-icon(size="31",color="#363636") mdi-qrcode-scan
                        //-     v-list-item-content 
                        //-         v-list-item-title
                        //-             span.fonte Ao ler QR-Code solicitar nome e telefone do cliente
                        //-     v-list-item-action.pt-3
                        //-         v-switch(@change="saveLocal",v-model="local.ask_name_and_phone" :color="$theme.secondary")
                        //- v-list-item
                        //-     v-avatar
                        //-             v-icon(size="31",color="#363636") mdi-account-tie-outline
                        //-     v-list-item-content 
                        //-         v-list-item-title
                        //-             span.fonte O Cliente poderá chamar o Garçom pelo App
                        //-     v-list-item-action.pt-3
                        //-         v-switch(@change="saveLocal",v-model="local.allow_call_waiter",:color="$theme.secondary")
                        //- v-list-item
                        //-     v-avatar
                        //-             v-icon(size="31",color="#363636") mdi-account-cash-outline
                        //-     v-list-item-content 
                        //-         v-list-item-title
                        //-             span.fonte Solicitar comissão para o garçom
                        //-     v-list-item-action.pt-3
                        //-         v-switch(@change="saveLocal",v-model="local.allow_waiter_commission",:color="$theme.secondary")
                        v-list-item
                            v-avatar
                                    v-icon(size="31",color="#363636") mdi-shopping-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Retirada no local
                            v-list-item-action.pt-3
                                v-switch(@change="saveLocal",v-model="local.local_pickup",:color="$theme.secondary")

</template>

<script>
import { EventBus } from "@/main";
export default {
  props: ["local"],
  methods: {
    changeConfigView(view) {
      EventBus.$emit("set-config-view", view);
    },
    saveLocal() {
      console.log("local", this.local);
      EventBus.$emit("saveLocal", this.local);
    }
  }
};
</script>

<style>
.config-local-avatar {
  width: 150px;
  height: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
}
</style>
